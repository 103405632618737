<template>
  <b-row align-h="center">
    <!--  xl="8" lg="8" md="7" -->
    <b-col xl="10" lg="9" md="9">
      <b-card>
        <b-row align-h="center">
          <div
            class="timerDiv d-flex align-items-center justify-content-center text-center"
            :style="styling"
            @click="startTime()"
          >
            <countdown
              ref="countdown"
              :time="time"
              :auto-start="false"
              :transform="transformSlotProps"
              @end="submitQuiz()"
              @progress="timeProgress"
            >
              <template slot-scope="props">
                <h3 class="mb-0 text-primary">
                  {{ props.minutes }} : {{ props.seconds }} s
                </h3>

                <span class="font-weight-bold">{{
                  !started ? "Start Now" : "remaining"
                }}</span>
              </template>
            </countdown>
          </div>
        </b-row>
      </b-card>

      <b-card v-if="started">
        <div v-if="!submitted">
          <b-row class="mb-1">
            <b-col>
              <h3 class="mb-0">
                Question {{ currentIndex + 1 }}/{{ questions.length }}
              </h3>
            </b-col>
          </b-row>
          <hr />
          <h5 class="mb-1">{{ currentQues.question.question }}</h5>
          <b-row
            v-for="(item, ind) in currentQues.options"
            :key="ind"
            class="mb-1"
          >
            <b-col class="d-flex">
              <b-form-input
                class="option"
                placeholder=""
                disabled
                v-model="item.option"
              />
              <b-button
                class="btn-icon rounded-circle ml-50"
                @click="setAnswers(ind)"
                :variant="item.choosed ? 'success' : 'outline-success'"
              >
                <feather-icon icon="CheckIcon" size="16" />
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="d-flex justify-content-between">
              <b-button
                v-if="attemptCount < questions.length - 1"
                variant="outline-primary"
                class=""
                @click="skip()"
              >
                Skip
              </b-button>
              <b-button
                v-if="attemptCount == questions.length - 1"
                variant="primary"
                class="ml-auto"
                @click="checkAndSubmit()"
              >
                Finish
              </b-button>
              <b-button v-else variant="primary" class="" @click="next()">
                Next
              </b-button>
              <!-- <b-button variant="primary" class="" @click="next()">
                {{ currentIndex == questions.length - 1 ? "Finish" : "Next" }}
              </b-button> -->
            </b-col>
          </b-row>
        </div>
        <b-row v-if="loading" align-h="center">
          <b-spinner
            class="m-5"
            style="width: 4rem; height: 4rem"
            variant="primary"
            label="loading"
          ></b-spinner>
        </b-row>
        <div v-if="submitted && !loading">
          <b-row class="mb-1">
            <b-col>
              <h3 class="mb-0">Quiz Completed</h3>
            </b-col>
            <b-col>
              <h4 class="mb-0 text-right">
                You Score: {{ score }}/{{ questions.length }}
              </h4>
            </b-col>
          </b-row>
          <hr />
          <b-row v-for="(item, ind) in questions" :key="ind" class="mb-1">
            <b-col>
              <h5 class="mb-1">{{ item.question.question }}</h5>
              <b-form-input
                :state="userSelected[ind].isCorrect"
                class="option mb-50"
                placeholder=""
                disabled
                v-model="userSelected[ind].option"
              />
              <b-form-input
                v-if="!userSelected[ind].isCorrect"
                :state="true"
                class="option mb-50"
                placeholder=""
                disabled
                v-model="item.options.find((el) => el.isCorrect).option"
              />
            </b-col>

            <!-- <b-col>
              <h6>{{ ind + 1 }}) {{ item.question.question }}</h6>
              <div v-if="!userSelected[ind].isCorrect" class="pl-1">
                <p class="mb-50 text-danger">
                  {{ userSelected[ind].option }} <feather-icon icon="XIcon" />
                </p>

                <p class="mb-0 text-success">
                  {{ item.options.find((el) => el.isCorrect).option }}
                  <feather-icon icon="CheckIcon" />
                </p>
              </div>

              <div v-else class="pl-1">
                <p class="mb-0 text-success">
                  {{ userSelected[ind].option }}
                  <feather-icon icon="CheckIcon" />
                </p>
              </div>
            </b-col> -->
          </b-row>
        </div>
      </b-card>
    </b-col>
    <!--  xl="4" lg="4" md="5" -->
    <b-col xl="2" lg="3" md="3" v-if="started">
      <b-card>
        <h4 class="mb-0">Progress</h4>
        <hr />
        <div
          v-for="(item, ind) in questions"
          :key="ind"
          class="d-flex flex-column align-items-center"
        >
          <b-button
            :variant="
              !unattempted.includes(ind)
                ? 'success'
                : ind == currentIndex
                ? 'primary'
                : 'outline-primary'
            "
            @click="move(ind)"
            class="btn-icon rounded-circle"
          >
            <feather-icon v-if="!unattempted.includes(ind)" icon="CheckIcon" />
            <span v-else>{{ ind + 1 }}</span>
          </b-button>
          <b-progress
            v-if="ind != questions.length - 1"
            class="my-progress"
            style="width: 20px; margin: 3px 0px"
            height="60px"
          >
            <b-progress-bar
              style="border-radius: 0"
              :variant="
                !unattempted.includes(ind) ? 'success' : 'light-secondary'
              "
              :value="100"
            ></b-progress-bar>
          </b-progress>
        </div>

        <!-- <b-progress class="w-100" height="20px" :value="100" show-progress>
          <b-progress-bar
            :class="!unattempted.includes(ind) ? 'text-light' : 'text-dark'"
            v-for="(item, ind) in questions"
            :key="ind"
            :value="100 / questions.length"
            :label="ind + 1"
            :variant="!unattempted.includes(ind) ? 'success' : 'light'"
          ></b-progress-bar>
        </b-progress> -->
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BCardTitle,
    BCardText,
    BSidebar,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    vSelect,
    BProgress,
    BProgressBar,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    // if (this.$store.state.quizData.started) {
    //   const obj = this.$store.state.quizData;
    //   this.time = obj.time;
    //   this.started = obj.started;
    //   this.submitted = obj.submitted;
    //   this.loading = obj.loading;
    //   this.questions = obj.questions;
    //   this.currentQues = obj.currentQues;
    //   this.currentIndex = obj.currentIndex;
    //   this.userSelected = obj.userSelected;
    //   this.score = obj.score;
    //   this.attemptCount = obj.attemptCount;
    //   this.skipIndexes = obj.skipIndexes;
    //   this.unattempted = obj.unattempted;
    //   console.log(this.$store.state.quizData);

    //   if (!this.submitted) {
    //     setTimeout(() => {
    //       this.$refs.countdown.start();
    //     }, 100);
    //   }
    // } else {
    //   this.loadQuiz();
    // }

    this.loadQuiz();
    this.$store.commit("setQuiz", {
      time: 60000,
      started: false,
      submitted: false,
      loading: false,
      questions: [],
      currentQues: {},
      currentIndex: 0,
      userSelected: [],
      score: 0,
      attemptCount: 0,
      skipIndexes: [],
      unattempted: [],
    });
  },
  data() {
    return {
      quizObj: {},
      time: 60000,
      currentTime: 0,
      started: false,
      submitted: false,
      loading: false,
      styling: {
        border: "1px solid " + $themeColors.primary,
      },
      questions: [
        {
          question: {
            id: 6,
            campusID: 1,
            question: "test",
            media: "",
            explanation: "",
            explanationMedia: "",
          },
          options: [
            {
              id: 3,
              campusID: 1,
              questionID: 6,
              option: "qwqea",
              isCorrect: true,
              choosed: false,
            },
            {
              id: 5,
              campusID: 1,
              questionID: 6,
              option: "e3rw",
              isCorrect: false,
              choosed: false,
            },
          ],
        },
        {
          question: {
            id: 10007,
            campusID: 1,
            question: "What is CU stand for?",
            media: "",
            explanation: "",
            explanationMedia: "",
          },
          options: [
            {
              id: 10007,
              campusID: 1,
              questionID: 10007,
              option: "Calculation Unit",
              isCorrect: false,
              choosed: false,
            },
            {
              id: 10008,
              campusID: 1,
              questionID: 10007,
              option: "Control User",
              isCorrect: false,
              choosed: false,
            },
            {
              id: 10009,
              campusID: 1,
              questionID: 10007,
              option: "Control Unit",
              isCorrect: true,
              choosed: false,
            },
          ],
        },
        {
          question: {
            id: 7,
            campusID: 1,
            question: "check",
            media: "",
            explanation: "",
            explanationMedia: "",
          },
          options: [
            {
              id: 3,
              campusID: 1,
              questionID: 7,
              option: "opt1",
              isCorrect: false,
              choosed: false,
            },
            {
              id: 5,
              campusID: 1,
              questionID: 7,
              option: "opt2",
              isCorrect: true,
              choosed: false,
            },
          ],
        },
      ],
      currentQues: {},
      currentIndex: 0,
      userSelected: [],
      score: 0,
      attemptCount: 0,
      skipIndexes: [],
      unattempted: [],
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    transformSlotProps(props) {
      const formattedProps = {};
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });
      return formattedProps;
    },
    startTime() {
      if (!this.started) {
        // console.log("st");
        this.$refs.countdown.start();
        this.started = true;

        this.saveState();
      }
    },

    saveState() {
      // console.log("save", this.currentTime);
      this.$store.commit("setQuiz", {
        time: this.currentTime,
        started: this.started,
        submitted: this.submitted,
        loading: false,
        questions: this.questions,
        currentQues: this.currentQues,
        currentIndex: this.currentIndex,
        userSelected: this.userSelected,
        score: this.score,
        attemptCount: this.attemptCount,
        skipIndexes: this.skipIndexes,
        unattempted: this.unattempted,
      });
    },
    timeProgress(data) {
      // console.log(data.totalMilliseconds);
      this.currentTime = data.totalMilliseconds;
      this.saveState();
    },

    async loadQuiz() {
      this.currentQues = this.questions[0];
      this.userSelected = this.questions.map(() => {
        return {
          isCorrect: false,
          option: "Not answered",
        };
      });
      this.currentTime = this.time;
      this.questions.forEach((el, ind) => {
        this.unattempted.push(ind);
      });
      console.log(this.userSelected, this.unattempted);

      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "?db=" +
      //     this.$store.state.userData.db +
      //     "&cID=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.quizObj = await this.get(obj);
    },

    setAnswers(ind) {
      this.currentQues.options.forEach((el, index) => {
        if (index == ind) {
          el.choosed = true;
          this.userSelected[this.currentIndex] = el;
        } else el.choosed = false;
      });
    },

    move(ind) {
      if (this.skipIndexes.includes(ind)) {
        // console.log(ind);
        this.currentIndex = ind;
        this.currentQues = this.questions[this.currentIndex];
      }
    },

    skip() {
      if (!this.skipIndexes.includes(this.currentIndex)) {
        this.skipIndexes.push(this.currentIndex);
      }

      let ind = this.unattempted.indexOf(this.currentIndex);
      let next;
      if (ind == this.unattempted.length - 1) {
        next = 0;
      } else {
        next = ind + 1;
      }

      this.currentIndex = this.unattempted[next];
      // console.log(next, this.currentIndex, this.unattempted);
      this.currentQues = this.questions[this.currentIndex];
    },

    next() {
      let opt = this.currentQues.options.find((el) => el.choosed);
      if (!opt) {
        this.$bvToast.toast("Please select an answer first", {
          title: "Warning!",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      }
      //  else if (this.currentIndex == this.questions.length - 1) {
      //   this.submitQuiz();
      // }
      else {
        let ind = this.unattempted.indexOf(this.currentIndex);
        let next;
        if (ind == this.unattempted.length - 1) {
          next = 0;
        } else {
          next = ind + 1;
        }
        const newVal = this.unattempted[next];

        this.unattempted = this.unattempted.filter(
          (el) => el != this.currentIndex
        );
        this.skipIndexes = this.skipIndexes.filter(
          (el) => el != this.currentIndex
        );
        this.currentIndex = newVal;

        // console.log(next, newVal, this.unattempted);
        // this.currentIndex += 1;
        this.currentQues = this.questions[this.currentIndex];
        this.attemptCount += 1;
      }
    },

    checkAndSubmit() {
      let opt = this.currentQues.options.find((el) => el.choosed);
      if (!opt) {
        this.$bvToast.toast("Please select an answer first", {
          title: "Warning!",
          variant: "warning",
          toaster: "b-toaster-top-center",
          solid: true,
        });
      } else {
        this.unattempted = this.unattempted.filter(
          (el) => el != this.currentIndex
        );
        this.skipIndexes = this.skipIndexes.filter(
          (el) => el != this.currentIndex
        );
        this.submitQuiz();
      }
    },

    submitQuiz() {
      //   console.log(this.userSelected);
      this.$refs.countdown.abort();
      this.submitted = true;
      this.loading = true;
      this.userSelected.forEach((el) => {
        // console.log(el.isCorrect);
        if (el.isCorrect) this.score += 1;
      });

      this.saveState();

      setTimeout(() => {
        this.loading = false;
      }, 2500);
    },
  },
};
</script>

<style scoped>
.option.form-control:disabled {
  background-color: white;
}

.timerDiv {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  cursor: pointer;
}
</style>
